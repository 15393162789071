var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "newusermodal",
        title: "Konto erstellen",
        "hide-backdrop": "",
        "header-class": "login-header",
        "footer-class": "login-footer",
        "body-class": "login-body"
      },
      on: { shown: _vm.onShow }
    },
    [
      _vm.show
        ? _c("b-form", { ref: "newUserForm", on: { submit: _vm.onSubmit } }, [
            _vm.form.success
              ? _c("div", [
                  _c("h1", [_vm._v("Fast fertig ...")]),
                  _c("p", [
                    _c("strong", [
                      _vm._v(
                        "Dein Konto wurde erfolgreich erstellt. Du musst aber noch Deine E-Mail Adresse bestätigen!"
                      )
                    ])
                  ]),
                  _c("p", [
                    _vm._v(
                      "Du solltest ein E-Mail mit Freischaltlink in der Inbox für "
                    ),
                    _c("strong", [_vm._v(_vm._s(this.keep.email))]),
                    _vm._v(" finden (bitte auch den Spamfolder prüfen).")
                  ])
                ])
              : _c(
                  "div",
                  [
                    _vm.form.hasError
                      ? _c("div", { staticClass: "alert alert-warning" }, [
                          _vm._v("Bitte überprüfe Deine Angaben:")
                        ])
                      : _vm._e(),
                    _c(
                      "b-row",
                      { staticClass: "my-2" },
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12" } },
                          [
                            _c("label", { attrs: { for: "regEmail" } }, [
                              _vm._v("E-Mail "),
                              _c("small", [_vm._v("(nicht öffentlich)")])
                            ]),
                            _c("b-form-input", {
                              attrs: {
                                id: "regEmail",
                                type: "email",
                                name: "email",
                                required: "",
                                "aria-describedby": "invalidEmailFeedback",
                                state: _vm.form.errors.email ? "invalid" : null
                              },
                              model: {
                                value: _vm.form.email,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "email",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.email"
                              }
                            }),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "invalidEmailFeedback" } },
                              [
                                _vm.form.errors.email === "exists"
                                  ? _c("span", [
                                      _vm._v(
                                        "Die angegebene E-Mail Adresse ist bereits vorhanden!"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "my-2" },
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12" } },
                          [
                            _c("label", { attrs: { for: "regUsername" } }, [
                              _vm._v("Benutzername "),
                              _c("small", [_vm._v("(Mindestens 3 Zeichen)")])
                            ]),
                            _c("b-form-input", {
                              attrs: {
                                id: "regUsername",
                                type: "text",
                                name: "username",
                                required: "",
                                pattern: ".{3,20}",
                                title:
                                  "Der Benutzername muss zwischen 3 und 20 Zeichen lang sein!",
                                "aria-describedby": "invalidUserNameFeedback",
                                state: _vm.form.errors.username
                                  ? "invalid"
                                  : null
                              },
                              model: {
                                value: _vm.form.username,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "username",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.username"
                              }
                            }),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "invalidUserNameFeedback" } },
                              [
                                _vm.form.errors.username === "exists"
                                  ? _c("span", [
                                      _vm._v(
                                        "Der angegebene Benutzername ist bereits vorhanden!"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "my-2" },
                      [
                        _c(
                          "b-col",
                          { attrs: { sm: "12" } },
                          [
                            _c("label", { attrs: { for: "regpassword" } }, [
                              _vm._v("Passwort "),
                              _c("small", [_vm._v("(Mindestens 8 Zeichen)")])
                            ]),
                            _c("b-form-input", {
                              attrs: {
                                id: "regpassword",
                                type: "password",
                                name: "password",
                                required: "",
                                pattern: ".{8,}",
                                title:
                                  "Das Passwort muss mindestens 8 Zeichen lang sein!"
                              },
                              model: {
                                value: _vm.form.password,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "password",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.password"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "my-2" },
                      [
                        _c("b-col", { attrs: { sm: "12" } }, [
                          _c("small", [
                            _vm._v(
                              "Nach dem Absenden dieses Formulars erhältst Du einen Link zum Freischalten des Kontos auf die angebenene E-Mail Adresse. Bitte stelle sicher, dass diese Adresse korrekt ist."
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
            !_vm.form.success
              ? _c(
                  "div",
                  { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                  [
                    _c("vue-recaptcha", {
                      ref: "recaptcha",
                      attrs: {
                        size: "invisible",
                        sitekey: "6LcT3nIUAAAAABRmt4tfA2bGZ-sBpAAroORFtdEV"
                      },
                      on: {
                        verify: _vm.createUser,
                        expired: _vm.onCaptchaExpired
                      }
                    }),
                    !_vm.loading
                      ? _c(
                          "b-button",
                          {
                            staticClass: "highlightbutton",
                            attrs: { block: "", type: "submit" }
                          },
                          [_vm._v("Registrieren")]
                        )
                      : _c(
                          "b-button",
                          {
                            staticClass: "highlightbutton",
                            attrs: { block: "", type: "submit", disabled: "" }
                          },
                          [_c("Spinner", { attrs: { color: "white" } })],
                          1
                        )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }