<template lang="pug">
    
    b-modal#newusermodal(title='Konto erstellen', hide-backdrop=true, header-class='login-header', footer-class='login-footer', body-class='login-body', @shown="onShow")
      b-form(@submit='onSubmit', v-if='show', ref="newUserForm")

          div(v-if='form.success')
              h1 Fast fertig ...
              p
                  strong Dein Konto wurde erfolgreich erstellt. Du musst aber noch Deine E-Mail Adresse bestätigen!
              p Du solltest ein E-Mail mit Freischaltlink in der Inbox für <strong>{{this.keep.email}}</strong> finden (bitte auch den Spamfolder prüfen).
          div(v-else)
              .alert.alert-warning(v-if='form.hasError')
                  | Bitte überprüfe Deine Angaben:

              b-row.my-2
                  b-col(sm="12")
                      label(for="regEmail") E-Mail <small>(nicht öffentlich)</small>
                      b-form-input#regEmail(type="email", name='email', v-model.trim='form.email', required,
                      aria-describedby="invalidEmailFeedback",
                      :state="form.errors.email ? 'invalid' : null")
                      b-form-invalid-feedback#invalidEmailFeedback
                          span(v-if="form.errors.email === 'exists'")
                              | Die angegebene E-Mail Adresse ist bereits vorhanden!

              b-row.my-2
                  b-col(sm="12")
                      label(for="regUsername") Benutzername <small>(Mindestens 3 Zeichen)</small>
                      b-form-input#regUsername(type="text", name="username", v-model.trim="form.username", required, pattern=".{3,20}", title="Der Benutzername muss zwischen 3 und 20 Zeichen lang sein!",
                      aria-describedby="invalidUserNameFeedback",
                      :state="form.errors.username ? 'invalid' : null")
                      b-form-invalid-feedback#invalidUserNameFeedback
                          span(v-if="form.errors.username === 'exists'")
                              | Der angegebene Benutzername ist bereits vorhanden!

              b-row.my-2
                  b-col(sm="12")
                      label(for="regpassword") Passwort <small>(Mindestens 8 Zeichen)</small>
                      b-form-input#regpassword(type="password", name="password", v-model.trim="form.password", required, pattern=".{8,}", title="Das Passwort muss mindestens 8 Zeichen lang sein!")

              b-row.my-2
                  b-col(sm="12")
                      small Nach dem Absenden dieses Formulars erhältst Du einen Link zum Freischalten des Kontos auf die angebenene E-Mail Adresse. Bitte stelle sicher, dass diese Adresse korrekt ist.

          div(slot="modal-footer", v-if='!form.success')
              vue-recaptcha(ref='recaptcha', @verify="createUser", @expired='onCaptchaExpired', size='invisible', sitekey='6LcT3nIUAAAAABRmt4tfA2bGZ-sBpAAroORFtdEV')
              b-button.highlightbutton(block=true, type='submit', v-if="!loading") Registrieren
              b-button.highlightbutton(block=true, type='submit', disabled, v-else)
                  Spinner(color='white')

</template>

<script>

  import VueRecaptcha from 'vue-recaptcha';
  import api          from '../../shared/api';
  import Spinner      from 'vue-spinkit';

  export default {
    name: "UserRegistration",
    components: { VueRecaptcha, Spinner },
    data() {
      return {
        loading: false,
        keep: {
          email: '',
        },
        show: true,
        form: {
          success: false,
          errors: {
            email: false,
            username: false,
            password: false,
          },
          username: '',
          email: '',
          password: '',
          hasError: '',
        }
      };
    },

    mounted() {

    },

    methods: {

      onShow(evt) {
        this.keep.email = '';
        this.resetErrors();
        this.resetForm();
      },
      onCreateUserClick() {
        this.$refs.newUserForm.submit();
      },

      onSubmit(evt) {
        evt.preventDefault();
        this.$refs.recaptcha.execute();
      },

      resetErrors() {
        this.form.errors.email = false;
        this.form.errors.username = false;
        this.form.errors.password = false;
        this.form.hasError = false;
      },

      resetForm() {
        this.form.email = '';
        this.form.username = '';
        this.form.password = '';
        this.form.success = false;
      },

      async createUser(recaptchaToken) {
        try {
          this.loading = true;
          this.resetErrors();
          console.log('trying create user');
          const createResult = await api.registerUser(recaptchaToken, this.form.username, this.form.email, this.form.password);
          console.log(createResult);
          this.$refs.recaptcha.reset();
          if (createResult.exists) {
            this.form.hasError = true;
            this.form.errors[createResult.exists] = 'exists';
            this.loading = false;
            return true;
          }

          this.keep.email = this.form.email;
          this.resetForm();
          this.resetErrors();
          this.form.success = true;
          this.loading = false;
          return true;


        } catch (err) {
          this.$refs.recaptcha.reset();
          this.loading = false;
          alert('Leider gibt es gerade ein Problem - bitte versuche es etwas später nochmals!');
        }

      },
      onCaptchaExpired() {
        this.$refs.recaptcha.reset();
      },
    }
  }
</script>

<style lang="less">


</style>
