var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.username
      ? _c(
          "div",
          { staticClass: "float-right" },
          [
            _c(
              "b-dropdown",
              { attrs: { "outline-variant-secondary": "" } },
              [
                _c(
                  "template",
                  { slot: "button-content" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "user" } }),
                    _c("span", [_vm._v("  " + _vm._s(_vm.username))])
                  ],
                  1
                ),
                _c("b-dropdown-item", { attrs: { href: "/logout" } }, [
                  _vm._v("Logout")
                ])
              ],
              2
            )
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "float-right" },
          [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.userloginmodal",
                    modifiers: { userloginmodal: true }
                  }
                ],
                staticClass: "navbtn",
                attrs: { "outline-variant-secondary": "" }
              },
              [_vm._v("User login")]
            ),
            _c("ResetPassword", {
              ref: "resetPasswordCmp",
              attrs: {
                resetUserId: _vm.resetUserId,
                resetToken: _vm.resetToken
              }
            }),
            _c("UserRegistration"),
            _c("UserLogin"),
            _c("ForgotPassword")
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }