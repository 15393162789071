var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "userloginmodal",
      attrs: {
        id: "userloginmodal",
        title: "Login",
        "hide-backdrop": "",
        "header-class": "login-header",
        "footer-class": "login-footer",
        "body-class": "login-body"
      },
      on: { shown: _vm.onShow }
    },
    [
      _c("b-form", { ref: "loginForm", on: { submit: _vm.onSubmit } }, [
        _vm.form.success
          ? _c("div", [
              _c("h1", [_vm._v("Login erfolgreich")]),
              _c("p", [_vm._v("Du bist jetzt angemeldet")])
            ])
          : _vm.form.error
          ? _c("div", [
              _c("div", { staticClass: "alert alert-warning" }, [
                _vm._v(_vm._s(_vm.userError))
              ])
            ])
          : _vm._e(),
        !_vm.form.success
          ? _c(
              "div",
              { attrs: { id: "login" } },
              [
                _c(
                  "b-row",
                  { staticClass: "my-1" },
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12" } },
                      [
                        _c("label", { attrs: { for: "input-email" } }, [
                          _vm._v("E-Mail")
                        ]),
                        _c("b-form-input", {
                          attrs: { id: "input-email", type: "email" },
                          model: {
                            value: _vm.form.email,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "email",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.email"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "my-1" },
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12" } },
                      [
                        _c("label", { attrs: { for: "input-password" } }, [
                          _vm._v("Passwort")
                        ]),
                        _c("b-form-input", {
                          attrs: {
                            id: "input-password",
                            type: "password",
                            pattern: ".{8,}",
                            title:
                              "Das Passwort muss mindestens 8 Zeichen lang sein!"
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "password",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.password"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        !_vm.form.success
          ? _c(
              "div",
              { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
              [
                _c("vue-recaptcha", {
                  ref: "recaptcha",
                  attrs: {
                    size: "invisible",
                    sitekey: "6LcT3nIUAAAAABRmt4tfA2bGZ-sBpAAroORFtdEV"
                  },
                  on: { verify: _vm.userLogin, expired: _vm.onCaptchaExpired }
                }),
                !_vm.loading
                  ? _c(
                      "b-button",
                      {
                        staticClass: "highlightbutton",
                        attrs: { block: "", type: "submit" }
                      },
                      [_vm._v("Login")]
                    )
                  : _c(
                      "b-button",
                      {
                        staticClass: "highlightbutton",
                        attrs: { block: "", type: "submit", disabled: "" }
                      },
                      [_c("Spinner", { attrs: { color: "white" } })],
                      1
                    ),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.forgotpasswordmodal",
                        modifiers: { forgotpasswordmodal: true }
                      }
                    ],
                    staticClass: "login-body",
                    attrs: { variant: "link", block: "" },
                    on: { click: _vm.hideModal }
                  },
                  [_vm._v("Passwort vergessen?")]
                ),
                _c("hr"),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.newusermodal",
                        modifiers: { newusermodal: true }
                      }
                    ],
                    staticClass: "navbtn",
                    attrs: { block: "" },
                    on: { click: _vm.hideModal }
                  },
                  [_vm._v("Neues Konto erstellen")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.form.success
          ? _c(
              "div",
              { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "highlightbutton",
                    attrs: { block: "" },
                    on: { click: _vm.hideModalAndReload }
                  },
                  [_vm._v("Schließen")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }