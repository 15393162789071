import axios from 'axios';

export default {
  async getReleasesForMedia(mediaId) {
    const response = await axios.get(`/api/media/${mediaId}/releases`);
    return response.data;
  },

  async getLatestReleases(offset) {
    const response = await axios.get(`/api/releases/latest/${offset}`);
    return response.data;
  },

  async fetchHosterLinks(recaptchaToken, releaseId, hoster, fphash) {
    const response = await axios.post(`/api/releases/${releaseId}/downloads/${hoster}`, { recaptchaToken, fphash });
    return response.data;
  },

  async registerUser(recaptchaToken, username, email, password) {
    const response = await axios.post('/api/users', {
      recaptchaToken, username, email, password,
    });
    return response.data;
  },

  async sendPasswordResetLink(recaptchaToken, email) {
    const response = await axios.post('/api/users/request-reset-password', {
      recaptchaToken, email,
    });
    return response.data;
  },

  async updatePassword(recaptchaToken, userId, resetToken, password) {
    const response = await axios.post(`/api/users/${userId}/password`, {
      recaptchaToken, resetToken, password,
    });
    return response.data;
  },
  async userLogin(recaptchaToken, email, password) {
    const response = await axios.put('/api/users/login', {
      recaptchaToken, email, password,
    });
    return response.data;
  },
};
