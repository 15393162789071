<template lang="pug">
    
    b-modal#userloginmodal(title='Login', hide-backdrop=true, header-class='login-header', footer-class='login-footer', body-class='login-body', @shown="onShow", ref="userloginmodal")  
      b-form(@submit='onSubmit', ref="loginForm")
          div(v-if='form.success')
              h1 Login erfolgreich
              p Du bist jetzt angemeldet
          div(v-else-if='form.error')
              .alert.alert-warning
                  | {{userError}}

          div(v-if='!form.success')#login
              b-row.my-1
                  b-col(sm="12")
                      label(for='input-email') E-Mail
                      b-form-input#input-email(type="email", v-model.trim="form.email")

              b-row.my-1
                  b-col(sm="12")
                      label(for='input-password') Passwort
                      b-form-input#input-password(type="password", v-model.trim="form.password", pattern=".{8,}", title="Das Passwort muss mindestens 8 Zeichen lang sein!")


          div(slot="modal-footer", v-if="!form.success")
              vue-recaptcha(ref='recaptcha', @verify="userLogin", @expired='onCaptchaExpired', size='invisible', sitekey='6LcT3nIUAAAAABRmt4tfA2bGZ-sBpAAroORFtdEV')
              b-button.highlightbutton(block=true, type='submit', v-if="!loading") Login
              b-button.highlightbutton(block=true, type='submit', disabled, v-else)
                  Spinner(color='white')
              b-button.login-body(variant="link", block=true, @click='hideModal', v-b-modal.forgotpasswordmodal) Passwort vergessen?
              hr
              b-button.navbtn(block=true, v-b-modal.newusermodal, @click='hideModal') Neues Konto erstellen

          div(slot="modal-footer", v-if="form.success")
              b-button.highlightbutton(block=true, @click="hideModalAndReload") Schließen
</template>

<script>
import Spinner from "vue-spinkit";
import VueRecaptcha from "vue-recaptcha";
import api from "../../shared/api";

const errors = {
  INVALID_CREDENTIALS: "Falsche E-Mail Adresse oder falsches Passwort!",
  NOT_VERIFIED:
    "Dieses Benutzerkonto wurde noch nicht bestätigt. Bitte prüfe Deine Mailbox. " +
    "Du kannst auch die 'Passwort vergesssen' Funktion nutzen um einen neuen Freischaltlink anzufordern.",
  UNKNOWN:
    "Fehler beim Bearbeiten Deiner Eingabe. Bitte versuche es später nochmals",
};

export default {
  name: "UserLogin",
  components: { VueRecaptcha, Spinner },
  data() {
    return {
      loading: false,
      form: {
        error: false,
        email: "",
        password: "",
        success: false,
      },
    };
  },

  computed: {
    userError() {
      if (!this.form.error) return false;
      return errors[this.form.error]
        ? errors[this.form.error]
        : errors["UNKNOWN"];
    },
  },

  methods: {
    resetErrors() {
      this.form.error = false;
    },
    resetForm() {
      this.form.email = "";
      this.form.password = "";
      this.form.success = "";
    },
    async userLogin(recaptchaToken) {
      try {
        this.loading = true;
        this.resetErrors();
        const loginResult = await api.userLogin(
          recaptchaToken,
          this.form.email,
          this.form.password
        );
        this.$refs.recaptcha.reset();
        this.loading = false;
        this.form.success = true;
        return true;
      } catch (err) {
        this.loading = false;
        this.$refs.recaptcha.reset();
        if (err.response && err.response.data && err.response.data.error) {
          this.form.error = err.response.data.error;
          return true;
        } else {
          this.form.error = "UNKNOWN";
        }
      }
    },
    onSubmit(evt) {
      console.log("xxxxxx");
      evt.preventDefault();
      this.$refs.recaptcha.execute();
    },
    onShow(evt) {
      this.resetForm();
      this.resetErrors();
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    hideModalAndReload() {
      this.$refs.userloginmodal.hide();

      if (location.href.includes("password-reset-confirm")) {
        location.replace("/");
      } else {
        location.reload();
      }
    },
    hideModal() {
      this.$refs.userloginmodal.hide();
    },
  },
};
</script>

<style scoped></style>
