<template lang="pug">
    
    b-modal#forgotpasswordmodal(title="Passwort vergessen", hide-backdrop=true, header-class='login-header', footer-class='login-footer', body-class='login-body', @shown="onShow")
      b-form(@submit='onSubmit', ref="forgotPasswordForm")
          div(v-if='form.success')
              h1 Link versendet
              p
                  | Der Link zum Zurücksetzen Deines Passworts wurde soeben an <strong>{{this.keep.email}}</strong> versendet (bitte ggf. den Spamfolder prüfen).
          div(v-else)
              b-row.my-1
                  b-col(sm="12")
                      label(for='pwinput-email') E-Mail
                      b-form-input#pwinput-email(type="email", required, v-model.trim='form.email',
                      aria-describedby="invalidEmailFeedback",
                      :state="form.errors.email ? 'invalid' : null")
                      b-form-invalid-feedback#invalidEmailFeedback
                          span(v-if="form.errors.email === 'notfound'")
                              | Die angegebene E-Mail Adresse ist leider unbekannt.


          div(slot="modal-footer", v-if="!form.success")
              vue-recaptcha(ref='recaptcha', @verify="sendResetLink", @expired='onCaptchaExpired', size='invisible', sitekey='6LcT3nIUAAAAABRmt4tfA2bGZ-sBpAAroORFtdEV')

              b-button.highlightbutton(block=true, type='submit', v-if="!loading") Link zum Zurücksetzen anfordern
              b-button.highlightbutton(block=true, type='submit', disabled, v-else)
                  Spinner(color='white')



</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  import api          from '../../shared/api';
  import Spinner from 'vue-spinkit';


  export default {
    name: "ForgotPassword",
    components: { VueRecaptcha, Spinner },
    data() {
      return {
        loading: false,
        keep: {
          email: '',
        },
        form: {
          email: '',
          success: false,
          hasError: false,
          errors: {
            email: false,
          }
        },

      };
    },
    methods: {

      resetErrors() {
        this.form.errors.email = false;
        this.form.hasError = false;
      },

      resetForm() {
        this.form.email = '';
        this.form.success = false;
      },

      async sendResetLink(recaptchaToken) {
        try {
          this.loading = true;
          this.resetErrors();
          const createResult = await api.sendPasswordResetLink(recaptchaToken, this.form.email);
          this.$refs.recaptcha.reset();
          this.loading = false;
          this.keep.email = this.form.email;
          this.form.success = true;
          return true;
        } catch (err) {
          this.loading = false;
          this.$refs.recaptcha.reset();
          if(err.response && err.response.status && err.response.status === 404) {
            this.form.hasError = true;
            this.form.errors['email'] = 'notfound';
            return true;
          }
          alert('Leider gibt es gerade ein Problem - bitte versuche es etwas später nochmals!');
        }
      },

      onShow(evt) {
        this.keep.email = '';
        this.resetForm();
        this.resetErrors();
      },
      onCaptchaExpired() {
        this.$refs.recaptcha.reset();
      },
      onSubmit(evt) {
        evt.preventDefault();
        this.$refs.recaptcha.execute();
      },
    },
  }
</script>

<style scoped>

</style>
