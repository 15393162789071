// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import UserMenu from './components/UserMenu.vue';


Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(BootstrapVue);

library.add(faUser);

Vue.component('font-awesome-icon', FontAwesomeIcon);

/* eslint-disable no-new */
new Vue({
  el: '#v-user-menu',
  components: { UserMenu },
  data: { resetUserId: null, resetToken: null, username: null },
  beforeMount() {
    if (this.$el.attributes['data-reset-token'] && this.$el.attributes['data-reset-userid']) {
      this.resetUserId = this.$el.attributes['data-reset-userid'].value;
      this.resetToken = this.$el.attributes['data-reset-token'].value;
    }
    this.username = this.$el.attributes['data-username'].value;
  },
  template: '<UserMenu :resetUserId="resetUserId" :resetToken="resetToken" :username="username"/>',

});
