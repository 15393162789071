var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "forgotpasswordmodal",
        title: "Passwort vergessen",
        "hide-backdrop": "",
        "header-class": "login-header",
        "footer-class": "login-footer",
        "body-class": "login-body"
      },
      on: { shown: _vm.onShow }
    },
    [
      _c(
        "b-form",
        { ref: "forgotPasswordForm", on: { submit: _vm.onSubmit } },
        [
          _vm.form.success
            ? _c("div", [
                _c("h1", [_vm._v("Link versendet")]),
                _c("p", [
                  _vm._v(
                    "Der Link zum Zurücksetzen Deines Passworts wurde soeben an "
                  ),
                  _c("strong", [_vm._v(_vm._s(this.keep.email))]),
                  _vm._v(" versendet (bitte ggf. den Spamfolder prüfen).")
                ])
              ])
            : _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "my-1" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12" } },
                        [
                          _c("label", { attrs: { for: "pwinput-email" } }, [
                            _vm._v("E-Mail")
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              id: "pwinput-email",
                              type: "email",
                              required: "",
                              "aria-describedby": "invalidEmailFeedback",
                              state: _vm.form.errors.email ? "invalid" : null
                            },
                            model: {
                              value: _vm.form.email,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.email"
                            }
                          }),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "invalidEmailFeedback" } },
                            [
                              _vm.form.errors.email === "notfound"
                                ? _c("span", [
                                    _vm._v(
                                      "Die angegebene E-Mail Adresse ist leider unbekannt."
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
          !_vm.form.success
            ? _c(
                "div",
                { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                [
                  _c("vue-recaptcha", {
                    ref: "recaptcha",
                    attrs: {
                      size: "invisible",
                      sitekey: "6LcT3nIUAAAAABRmt4tfA2bGZ-sBpAAroORFtdEV"
                    },
                    on: {
                      verify: _vm.sendResetLink,
                      expired: _vm.onCaptchaExpired
                    }
                  }),
                  !_vm.loading
                    ? _c(
                        "b-button",
                        {
                          staticClass: "highlightbutton",
                          attrs: { block: "", type: "submit" }
                        },
                        [_vm._v("Link zum Zurücksetzen anfordern")]
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "highlightbutton",
                          attrs: { block: "", type: "submit", disabled: "" }
                        },
                        [_c("Spinner", { attrs: { color: "white" } })],
                        1
                      )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }