<template lang="pug">
    span
        .float-right(v-if="username")
            b-dropdown(outline-variant-secondary)
                template(slot='button-content')
                    font-awesome-icon(icon='user')
                    span &nbsp; {{username}}
                b-dropdown-item(href='/logout') Logout

        .float-right(v-else)
            b-button.navbtn(outline-variant-secondary, v-b-modal.userloginmodal) User login

            ResetPassword(ref="resetPasswordCmp", :resetUserId="resetUserId", :resetToken="resetToken")
            UserRegistration
            UserLogin
            ForgotPassword

</template>

<script>

  import UserRegistration from './UserRegistration';
  import UserLogin        from './UserLogin';
  import ForgotPassword   from './ForgotPassword';
  import ResetPassword   from './ResetPassword';

  export default {
    name: "UserMenu",
    components: { UserRegistration, UserLogin, ForgotPassword, ResetPassword },
    props: ['resetUserId', 'resetToken', 'username'],
    data() {
      return {};
    },

    mounted() {
        if(this.resetUserId && this.resetToken) {
          this.$refs.resetPasswordCmp.$refs.resetPasswordModalref.show();
        }
    },

    methods: {}
  }
</script>

<style lang="less">
    .login-header {
        background-color: #f65f24;
        color: #ffffff;
    }

    .login-footer {
        background-color: #020202;
        color: #ffffff;
        display: block;

        hr {
            border: 0;
            height: 1px;
            background: #333333;
        }

        .highlightbutton {
            background: #f65f24;
            border-color: #f65f24;

            &:hover {
                color: white;
                border-color: #b35a24;
                background: #b35a24;
            }
        }
    }

    .btn.login-body {
        color: #f65f24;
        margin: 0;
        padding: 0;
    }
</style>
